<template>
  <div class="mobile-view">
    <div class="img img1"></div>

    <div class="content">
      <div class="item" v-for="item in list" :key="item.couponCode">
        <van-row class="first" align="center">
          <van-col :span="7" :style="{ fontSize: nameFontSize }">
            <span class="van-ellipsis">{{ item.activityName }}</span>
          </van-col>

          <van-col
            :span="15"
            :style="{ fontSize: codeFontSize }"
            @click="copy(item.couponCode)"
          >
            <span class="van-ellipsis">{{ item.couponCode }}</span>
          </van-col>

          <van-col :span="2" class="text-right" @click="copy(item.couponCode)">
            <span
              class="copy"
              :style="{ width: normalFontSize, height: normalFontSize }"
            ></span>
          </van-col>
        </van-row>

        <van-row class="second" align="center">
          <van-col :span="18">
            <span
              class="van-ellipsis"
              :style="{
                fontSize: normalFontSize,
              }"
              >兑换时间截止：{{ item.deadlineTime }}</span
            >
          </van-col>

          <van-col :span="6" class="text-right">
            <van-button
              round
              class="convert-button"
              :style="{
                fontSize: normalFontSize,
                width: '100%',
                height: `${ratio * 30}px`,
              }"
              color="linear-gradient(to right, #EB702D, #E93423)"
              @click="convert(item)"
              >去兑换</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="img img2"></div>
  </div>
</template>

<script>
import { watch, ref, computed } from 'vue';
import { Notify, Button, Col, Row } from 'vant';
import { useWindowSize } from '@vant/use';
import { getMyCouponCodePage } from '@/api/api';
import hybird from '@/composables/hybird';

export default {
  name: 'Activity10Gift',

  setup() {
    const { hybirdCall } = hybird();
    const { width, height } = useWindowSize();
    let ratio = ref(1);
    ratio.value = (width.value > 768 ? 768 : width.value) / 375;

    watch([width, height], () => {
      ratio.value = (width.value > 768 ? 768 : width.value) / 375;
    });

    const nameFontSize = computed(() => `${22 * ratio.value}px`);

    const normalFontSize = computed(() => `${12 * ratio.value}px`);

    const codeFontSize = computed(() => `${20 * ratio.value}px`);

    return {
      hybirdCall,
      ratio,
      nameFontSize,
      normalFontSize,
      codeFontSize,
    };
  },

  data() {
    return {
      id: this.$route.query.id || '',
      token: this.$route.query.token || '',
      list: [],
    };
  },

  created() {
    if (!this.id || !this.token) {
      return Notify('页面已过期');
    }

    if (this.token) {
      setTimeout(() => {
        this.hybirdCall(
          'activityJSMethod',
          JSON.stringify({
            type: 'title',
            value: '10.10 要你！要你！ - 易校招',
          })
        );
      }, 1000);
    }

    this.getCoupons();
  },

  methods: {
    getCoupons() {
      getMyCouponCodePage({
        headers: {
          token: this.token,
        },
        params: {
          activityId: this.id,
          pageNum: 1,
          pageSize: 100,
        },
      }).then((res) => {
        if (Array.isArray(res.records)) {
          this.list = res.records;
        }
      });
    },

    copy(code) {
      this.safeHybirdCall(
        JSON.stringify({
          type: 'copy',
          value: code || '',
        })
      );
    },

    convert(item) {
      const index =
        item.activityName == '活动一'
          ? 1
          : item.activityName == '活动二'
          ? 2
          : 0;

      this.safeHybirdCall(
        JSON.stringify({
          type: 'convert',
          index: index,
          shopUrl: item.redirectUrl || '',
        })
      );
    },

    safeHybirdCall(param) {
      if (this.token) {
        this.hybirdCall('activityJSMethod', param);
      }
    },
  },

  components: {
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
  },
};
</script>

<style lang="scss" scoped>
.mobile-view {
  position: fixed;
  top: 0;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

.img {
  width: 100%;
  height: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.img1 {
  padding-top: 52.5%;
  flex: 0 0 auto;
  background-image: url('~@/assets/images/activity10/gift1.png');
}

.content {
  flex: 1;
  background-image: url('~@/assets/images/activity10/bg.png');
  background-repeat: repeat;
  padding: 0 5%;
  overflow-y: auto;

  .item {
    background: url('~@/assets/images/activity10/gift-block.png') no-repeat;
    background-size: cover;
    height: 0;
    padding-top: 28.2%;
    margin-bottom: 1em;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    .first {
      position: absolute;
      top: 10%;
      left: 5%;
      width: 90%;
      color: #a75824;

      .copy {
        display: inline-block;
        background: url('~@/assets/images/activity10/copy.png') no-repeat;
        background-size: cover;
      }
    }

    .second {
      position: absolute;
      bottom: 7%;
      left: 5%;
      width: 90%;
      color: #c87f4e;
    }
  }
}

.img2 {
  padding-top: 32.3%;
  flex: 0 0 auto;
  background-image: url('~@/assets/images/activity10/gift2.png');
}
</style>
