export default function filters() {
  const hybirdCall = (func, val) => {
    window[func] = () => alert(val);

    setTimeout(() => {
      hybird[func](val); // eslint-disable-line no-undef
    });

    setTimeout(() => {
      window.webkit.messageHandlers[func].postMessage(val);
    });
  };

  return {
    hybirdCall,
  };
}
